import {PivotAnnotation} from '../pivot-table/pivot-annotation';

export class AnnotatedValue {
	constructor(
		public value: number | string,
		public annotations: Array<PivotAnnotation>,
		public normalizationFactor: number = 1
	) {	}

}

// Defines the configuration for a dimensional field in a hierarchy
// where <T> represents the data type for the data source array.
export class HierarchyFieldConfig<T extends object> {
	static defaultSortOrder = (value: number | string | null) => { return value ?? '' };
	static defaultDisplayValue = (value: number | string | null) => { return value?.toString() ?? '' };
	static defaultAccountAnnotation = () => { return null };

	// We can force the data cube and pivot table to include fixed values for this level of the hierarchy
	// and add annotations as necessary; mostly to be used for annual filers to show years with missing annotations
	public mustIncludeValues: Array<AnnotatedValue> = []

	constructor(
		public readonly name: keyof T, // Field to use from the original data source
		public readonly expandable: boolean = true, // Can the level in the hierarchy be expanded/collapsed
		public readonly expandedDefault = false, // Default expanded state; only the first field in the hierarchy marked true will have an effect
		public readonly sortable: boolean = false, // For columns only, allows for sorting rows by the aggregate totals in a column
		public readonly sortOrder = HierarchyFieldConfig.defaultSortOrder, // sorting function for this field
		public readonly displayValue = HierarchyFieldConfig.defaultDisplayValue, // a display label function for this field
		public readonly accountAnnotation: (value: number | string | null) => string | null
			= HierarchyFieldConfig.defaultAccountAnnotation, // string value to include as PivotAnnotationType.accountCode
	) {}
}
