import {MCAG} from "../mcag";
import {GovTypeCode} from "../gov-type-code";
import {AmountFormat, Ranking, Section} from "./ranking";
import {DatasetDetail} from "../datasets/dataset-type";
import {LiveWithDetail} from '../datasets/live';
import {SnapshotWithDetail} from '../datasets/snapshot';
import {RankingSortOrder} from "./ranking-sort-order";
import {FUND_CATEGORIES} from '../funds/fund-category';
import {SchoolsWithDetail} from '../datasets/schools';

export class FinancialRanking {
	mcag: MCAG;
	year: number;
	govTypeCode: GovTypeCode;
	rank: number;
	fundCategoryId: number;
	fsSectionId: number;
	basicAccountId: number;
	amount: number;

	// not part of API response
	private readonly datasetDetail: DatasetDetail;

	constructor(
		mcag: MCAG,
		year: number,
		govTypeCode: GovTypeCode,
		rank: number,
		fundCategoryId: number,
		fsSectionId: number,
		basicAccountId: number,
		amount: number,
		datasetDetail: DatasetDetail
	) {
		this.mcag = mcag;
		this.year = year;
		this.govTypeCode = govTypeCode;
		this.rank = rank;
		this.fundCategoryId = fundCategoryId;
		this.fsSectionId = fsSectionId;
		this.basicAccountId = basicAccountId;
		this.amount = amount;
		this.datasetDetail = datasetDetail;
	}

	toRanking = () => {
		// link logic
		const link = ['/explore/dollars/bars', this.getRankingLogicalAccount(), 'governments', {govTypeCode: this.govTypeCode}];

		// ranking category name logic
		const name = this.getRankingName();

		// sort order logic
		let sortOrder;
		if (name === 'Revenues') {
			sortOrder = RankingSortOrder.saoAnnualFilingRevenues;
		} else if (name === 'Expenditures') {
			sortOrder = RankingSortOrder.saoAnnualFilingExpenditures;
		} else if (name === 'Taxes (Revenues)') {
			sortOrder = RankingSortOrder.saoAnnualFilingTaxes;
		} else if (name === 'Public Safety (Expenditures)') {
			sortOrder = RankingSortOrder.saoAnnualFilingPublicSafety;
		} else {
			throw Error('Category name sort order not defined');
		}

		return new Ranking(
			this.mcag,
			this.year,
			Section.financial,
			link,
			name,
			sortOrder,
			this.rank,
			this.amount,
			AmountFormat.currency
		)
	}

	/**
	 * Logical Account lookup for Ranking Trend (to enable navigation to dollars profile)
	 */
	private getRankingLogicalAccount(): string | undefined {
		if (this.datasetDetail instanceof SnapshotWithDetail || this.datasetDetail instanceof SchoolsWithDetail) {
			if (this.basicAccountId) {
				return this.datasetDetail.detail.getReference('accountDescriptors', this.basicAccountId)?.logicalAccount;
			} else {
				return this.datasetDetail.detail.getReference('financialSummarySections', this.fsSectionId)?.logicalAccount;
			}
		} else {
			throw new Error(`Dataset detail couldn't resolve`)
		}
	}

	/**
	 * Name lookup for Ranking Trend
	 */
	private getRankingName(): string {
		let name = '';
		if (this.datasetDetail instanceof SnapshotWithDetail || this.datasetDetail instanceof SchoolsWithDetail) {

			if (this.fundCategoryId) {
				name = FUND_CATEGORIES.find(fc => fc.id === this.fundCategoryId)?.name ?? '';
				if (name === 'Proprietary') {
					name = 'Enterprise';  // Duane's spreadsheet specifies this terminology
				}
			}

			if (this.basicAccountId) {
				name = name + (name.length > 0 ? ' ' : '')
					+ this.datasetDetail.detail.getReference('accountDescriptors', this.basicAccountId)?.name
					+ ' (' + this.datasetDetail.detail.getReference('financialSummarySections', this.fsSectionId)?.name + ')';
			} else {
				let text = this.datasetDetail.detail.getReference('financialSummarySections', this.fsSectionId)?.name;
				if (this.fundCategoryId === 2 && text === 'Expenditures') {
					text = text + '/Expenses';
				}
				name = name + (name.length > 0 ? ' ' : '') + text;
			}

			return name;
		} else {
			throw new Error(`Dataset detail couldn't resolve`)
		}
	}
}


