export const HintInformation = {
	AnnualFiling: 'Data in FIT is certified by local governments as part of their annual report filing process under state law (RCW 43.09.230) and is sourced from its most recent submission, as of the selected publish date. Data presented in FIT is subject to change as governments submit or update their financial data.',
	OSPI: 'School district annual financial statement data is prepared and submitted by the district to the Office of the Superintendent of Public Instruction (OSPI) in accordance with WAC 392-117-035. FIT updates this data once per year.',
	None: 'Financial data for some types of local and state governments is not available in FIT because those governments are not part of the annual filing data collection process conducted by the State Auditor’s Office.',
	FinancialHealthIndicators: 'Having a particular Outlook does not necessarily indicate financial stability or stress. Indicators are designed to alert viewers to potential financial health concerns by comparing the ratios to our recommended thresholds. In many cases, further inquiry is recommended, as local governments may have measures different than our guidelines.',
	AuthenticatedUserNote: 'This data is available only to authenticated users with access to this government.',
	GAAP: 'Generally Accepted Accounting Principles, or GAAP, is the nationally recognized method of how and when to record financial transactions. The rules in GAAP are established by the Government Accounting Standards Board (GASB). Washington governments can choose to report using GAAP or another alternative. The BARS GAAP Basis Manual explains the requirements for this type of accounting and reporting.',
	Cash: 'Cash basis is an accounting method that typically records revenue at the time cash is received and expenditures when cash is disbursed. The majority of smaller governments in Washington use this type of accounting. The BARS Cash Basis Manual explains the requirements for this type of accounting and reporting.',
	ModifiedAccrual: 'The modified accrual method of accounting typically followed by school districts recognizes current revenues and expenditures. In this method, there are no district-wide financial statements. This means it does not include some long-term activity such as capital assets or debt. The Accounting Manual for Public School Districts explains this type of accounting and reporting. The Office of Superintendent of Public Instruction (OSPI) and the State Auditor’s Office work together to maintain this manual.',
	SchoolsCash: 'Cash-basis accounting for school districts is an accounting method that records revenue at the time cash is received and expenditures when cash is disbursed.  The Accounting Manual for Public School Districts explains this type of accounting and reporting. The Office of Superintendent of Public Instruction (OSPI) and the  State Auditor’s Office work together to maintain this manual.',
	Headcount: 'This is the average monthly Headcount of all grade levels for the school year indicated in parentheses.',
	SnapshotBaseline: 'If this snapshot is a revision for this year, please indicate whether it is the new baseline.  In the future, users may have the option to select specific snapshot revisions to use in FIT and setting a baseline for the year will allow for a snapshot revision other than the latest or the initial revision to be used as the default',
	FinancialReport: {
		Summary: 'This is all the financial data that is available for this entity.',
		Revenues: 'Revenue is money received by a government that is used to support the activities it provides. This money comes primarily from taxes, fees, charges and grants.  Does not include other increases like loan proceeds.',
		DebtAndLiabilities: 'Outstanding obligations reported by the government.',
		Expenditures: 'For the purposes of this system, expenditures are the money spent by a government for salaries, benefits, supplies and services.  Does not include other decreases like debt service.',
	}
}
