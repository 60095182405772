export class SelectedFundNode {
	id: string;
    constructor(
        public value: number | string | null, // fund values (id) are strings
        public field: FundField,
    ) {
		this.id = `${field}|${value}`;
	}
}

export class FundNode extends SelectedFundNode{
    constructor(
        public fundCategoryId: number | null,
        public fundTypeId: number | null,
        public fund: string | null,
		public allFunds: 'All Funds' | null,
        public selected: boolean,
        public isVisible: boolean,
        public disabled: boolean,
        public label: string,
        public focus: boolean,
		public isExpandable: boolean,
        public expanded: boolean,
        public depth: number,

        value: number | string | 'All Funds',
        field: FundField,
    ) {
        super(value, field)
    }
}

export enum FundField {
    fundCategoryId = 'fundCategoryId',
    fundTypeId = 'fundTypeId',
    fund = 'fund',
	allFunds = 'allFunds'
}

