<div class="loading-panel">
	<div class="pulse-blocks" [ngClass]="size">
		<div [ngClass]="size"></div>
		<div [ngClass]="size"></div>
		<div [ngClass]="size"></div>
	</div>
	@if (loadingText.length) {
		<h4 [ngClass]="size">{{loadingText}}</h4>
	}
</div>

