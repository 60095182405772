import {ALL_COUNTIES, SelectedCounties} from '../../components/counties-selector/counties-model';

// if param is 'all', use that. otherwise split string and turn into array of numbers
export const selectedCountiesStringParser = (counties: string): SelectedCounties | undefined =>
{
	try {
		return counties === ALL_COUNTIES ? ALL_COUNTIES : counties.split(',').map(Number);
	} catch {
		return undefined;
	}
}
