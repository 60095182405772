/**
 * Function that compares two arrays of number | string values
 * @param a - first array to compare
 * @param b - second array to compare
 */
export const match = (a: Array<number | string>, b: Array<number | string>): boolean =>
{
	// If arrays are different sizes, they don't match
	if (a.length !== b.length) {
		return false;
	}

	for (let i = 0; i < a.length; i++) {
		// If any array element are not the same types or are not equal, they don't match
		if (a[i] !== b[i]) {
			return false;
		}
	}

	return true;
}
