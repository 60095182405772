import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MCAG} from './models/mcag';
import {LocalGovernment} from './models/local-government';
import {Observable} from 'rxjs';
import {ODataCollectionResult} from '../odata-collection-result';
import {map, shareReplay} from 'rxjs/operators';
import {GovTypeCode} from './models/gov-type-code';
import {GovernmentType} from './models/government-type';
import {ParamMap} from '@angular/router';
import {GovernmentId} from './models/government-id';
import {LiveWithDetail} from './models/datasets/live';
import {Snapshot, SnapshotWithDetail} from './models/datasets/snapshot';
import {SchoolsWithDetail} from './models/datasets/schools';
import {IndicatorReport} from './models/indicators/indicator-report';
import {FilingStatus} from './models/filing-status';
import {Enrollment} from './models/schools/enrollment';
import {DatasetConfig, DatasetDetail, DatasetType} from './models/datasets/dataset-type';
import {County} from './models/snapshots/county';
import {IndicatorSummary} from './models/indicators/indicator-summary';
import {PublishSnapshotData} from './models/snapshots/publish-snapshot';
import {GovernmentMetric} from './models/government-metric';
import {FinancialRanking} from './models/rankings/financial-ranking';
import {EnrollmentRanking} from './models/rankings/enrollment-ranking';
import {Ranking} from './models/rankings/ranking';
import {Share, ShareMode} from '../share';
import {FundCategory} from './models/funds/fund-category';
import {FundType} from './models/funds/fund-type';
import {IndicatorGroup} from './models/indicators/indicator-group';
import {DeleteSnapshotsData} from './models/snapshots/delete-snapshots';
import {JobInstanceStatus} from './models/job-instance-status';

@Injectable({
	providedIn: 'root'
})
export class FitApiService {

	// todo This type is wrong because the result is a *subset* of LocalGovernment
	//  this also doesn't belong here because its use-case is only the map
	governmentsForList = (onlyShowActive = false) => this.http.get<ODataCollectionResult<LocalGovernment>>(
		`LocalGovernments?${onlyShowActive ? `$filter=status eq 'Active'&` : ''}
		    $select=mcag,countyCodes,legalName,commonName,sortableCommonName,fullNameForSearching,sortableFullNameForSearching,govTypeCode,isLocalGovernment,latitude,longitude`
	).pipe(
		map(result =>
			result.value.map(x => new LocalGovernment(x))
		),
		shareReplay()
	);

	// By default return only active governments for the government/map search page
	governmentsForSearch = this.governmentsForList(true);

	// same with this return type
	governmentTypesForList = this.http.get<ODataCollectionResult<GovernmentType>>(
		'GovernmentTypes?$select=activeCount,canHaveIndicators,isLocalGovernment,financialsDatasetSource,code,description,descriptionPlural'
	).pipe(
		map(result => result.value),
		shareReplay()
	);

	countiesForList = this.http.get<ODataCollectionResult<County>>(
		'Counties'
	).pipe(
		map(result => result.value),
		shareReplay()
	);

	constructor(
		private http: HttpClient
	) {
	}

	// region Live & Unauthenticated
	/**
	 *
	 * @param mcag
	 */
	@Share()
	getLocalGovernment(mcag: MCAG): Observable<LocalGovernment> {
		return this.getLocalGovernments(new GovernmentId(mcag)).pipe(
			map(govts => {
				const government = govts[0];
				if (!government) {
					throw new Error(`Could not locate LocalGovernment for MCAG '${mcag}'.`);
				}
				return government;
			})
		);
	}

	/**
	 *
	 * @param governmentId
	 */
	@Share()
	getLocalGovernments(governmentId: GovernmentId): Observable<Array<LocalGovernment>> {
		return this.http.get<ODataCollectionResult<LocalGovernment>>(`LocalGovernments?$filter=${governmentId.parameterName} eq '${governmentId}'`).pipe(
			map(result => result?.value.map(govt => new LocalGovernment(govt)))
		);
	}

	@Share()
	getGovernmentType(govTypeCode: GovTypeCode): Observable<GovernmentType> {
		return this.http.get<ODataCollectionResult<GovernmentType>>(`GovernmentTypes?$filter=code eq '${govTypeCode}'`).pipe(
			map(result => {
				const governmentType = result?.value?.[0];
				if (!governmentType) {
					throw new Error(`Could not locate GovernmentType for GovType '${govTypeCode}'.`);
				}
				return governmentType;
			})
		);
	}

	getGovernmentIdFromRoute(routeParams: Observable<ParamMap>): Observable<GovernmentId> {
		return routeParams.pipe(
			map(params => {
				const mcag
					= params.get('mcag') as MCAG;
				const govTypeCode
					= params.get('govTypeCode') as GovTypeCode;
				const id
					= mcag ?? govTypeCode;
				if (id === undefined) {
					throw new Error(`Cannot resolve GovernmentId from route params.`)
				}
				return new GovernmentId(id);
			})
		)
	}

	// endregion

	// region Snapshot or School Based

	/**
	 * Explictly get Snapshots without any detail for use when publishing a new snapshot or deleting existing snapshots
	 */
	@Share()
	getSnapshots(): Observable<Array<Snapshot>> {
		const datasetTypeConfig = DatasetConfig[DatasetType.Snapshot];
		return this.http.get<ODataCollectionResult<Snapshot>>(`${datasetTypeConfig.route}`)
			.pipe(map(x => x.value));
	}

	@Share()
	getFilingStatuses(dataset: DatasetDetail, filter?: string): Observable<Array<FilingStatus>> {
		return this.http.get<ODataCollectionResult<FilingStatus>>(`${dataset.route}/FilingStatuses?${filter ? '$filter=' + filter : ''}`)
			.pipe(map(x => x.value));
	}

	/**
	 * Return list of Government Metrics for provided snapshotId
	 * @param dataset
	 * @param filter - OData filter
	 */
    @Share()
	getGovernmentMetrics(dataset: LiveWithDetail | SnapshotWithDetail, filter?: string): Observable<Array<GovernmentMetric>> {
		return this.http.get<ODataCollectionResult<GovernmentMetric>>(`${dataset.route}/GovernmentMetrics?${filter ? '$filter=' + filter : ''}`)
			.pipe(map(x => x.value));
	}

	/**
	 * Return live filing statuses
	 */
	@Share()
	getLiveAnnualFilingStatuses(): Observable<Array<FilingStatus>> {
		return this.http.get<ODataCollectionResult<FilingStatus>>(`Live/FilingStatuses?$filter=datasetSource eq 'AnnualFiling'`)
			.pipe(map(x => x.value));
	}

	/**
	 * Return live government metrics
	 */
	@Share()
	getLiveGovernmentMetrics(): Observable<Array<GovernmentMetric>> {
		return this.http.get<ODataCollectionResult<GovernmentMetric>>(`Live/GovernmentMetrics`)
			.pipe(map(x => x.value));
	}

	/**
	 * Return indicator reports
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getIndicatorReports(dataset: LiveWithDetail | SnapshotWithDetail | SchoolsWithDetail, filter?: string): Observable<Array<IndicatorReport>> {
		return this.http.get<ODataCollectionResult<IndicatorReport>>(`${dataset.route}/IndicatorReports?${filter ? '$filter=' + filter : ''}`)
			.pipe(map(x => x.value));
	}

	/**
	 * Get IndicatorSummaries (IndicatorOutlook for each Indicator) by Government.
	 * Note: If just startYear is passed into function, only include one year of data
	 * @param dataset
	 * @param startYear
	 * @param endYear
	 */
	@Share()
	getIndicatorSummaries(
		dataset: DatasetDetail,
		startYear: number,
		endYear?: number
	): Observable<Array<IndicatorSummary>> {
		return this.http.get<ODataCollectionResult<IndicatorSummary>>(
				// if there is no endYear, startYear and endYear are the same
			`${dataset.route}/IndicatorSummaries/For(startYear=${startYear},endYear=${endYear ? endYear : startYear})`
		).pipe(
			map(result => result.value),
			map(arr => arr.map(x => new IndicatorSummary(x)))
		);
	}

	/**
	 * Return indicator groups
	 * @param dataset
	 * @param mcag
	 * @param startYear
	 * @param endYear
	 */
	@Share()
	getIndicatorGroups(
		dataset: DatasetDetail,
		mcag: MCAG,
		startYear: number,
		endYear: number
	): Observable<Array<IndicatorGroup>> {
		return this.http.get<ODataCollectionResult<IndicatorGroup>>(
			// if there is no endYear, startYear and endYear are the same
			`${dataset?.route}/IndicatorGroups/For(mcag='${mcag}',startYear=${startYear},endYear=${endYear})`
		).pipe(
			map(result => result.value),
			map(arr => arr.map(x => new IndicatorGroup(x.group, x.mcag, x.instantiationLevel, x.report, x.filingBasis, x.outlookInfo, x.indicators)))
		);
	}

	/**
	 * Return rankings
	 * @param dataset
	 * @param query
	 */
    @Share()
	getRankings(dataset: SchoolsWithDetail | SnapshotWithDetail, query: string): Observable<Array<Ranking>> {
		const routePath = dataset instanceof SnapshotWithDetail ? 'Schedule1s' : 'FinancialReports';
		return this.http.get<ODataCollectionResult<FinancialRanking>>(`${dataset.route}/${routePath}/${query}`)
			.pipe(map(x =>
				x.value.map(record =>
					new FinancialRanking(
						record.mcag,
						record.year,
						record.govTypeCode,
						record.rank,
						record.fundCategoryId,
						record.fsSectionId,
						record.basicAccountId,
						record.amount,
						dataset
					).toRanking()
				)
			));
	}

	/**
	 * Return enrollment rankings (for schools)
	 * @param dataset
	 * @param filter
	 */
    @Share()
	getEnrollmentRankingsForSchools(dataset: DatasetDetail, filter?: string): Observable<Array<Ranking>> {
		return this.http.get<ODataCollectionResult<EnrollmentRanking>>(`${dataset.route}/Enrollments/Rankings${filter}`)
			.pipe(map(x =>
				x.value.map(record =>
					new EnrollmentRanking(
						record.mcag,
						record.coDist,
						record.govTypeCode,
						record.fy,
						record.schoolYear,
						record.enrollmentType,
						record.averagesForAllGrades,
						record.rank
					).toRanking()
				)
			));
	}

	/**
	 * Return enrollment totals for schools
	 * @param dataset
	 * @param filter
	 */
	@Share()
	getEnrollments(dataset: DatasetDetail, filter?: string): Observable<Array<Enrollment>> {
		return this.http.get<ODataCollectionResult<Enrollment>>(`${dataset.route}/Enrollments?$filter=${filter}`).pipe(map(x => x.value));
	}

	/**
	 * Return counts of OSPI governments that filed for fiscal year
	 * @param dataset
	 * @param fiscalYear
	 * @param govTypeCode
	 */
    @Share()
	getOSPIFilersCount(dataset: DatasetDetail, fiscalYear: number, govTypeCode: GovTypeCode): Observable<number> {
		if (dataset instanceof SchoolsWithDetail) {
			return this.http.get<ODataCollectionResult<{ total: number }>>(`${dataset.route}/FinancialReportAggregationsByGovt?$apply=filter(year eq ${fiscalYear} and basicAccountId eq null and fundCategoryId eq null and totalAmount ne 0 and govTypeCode eq '${govTypeCode}')/aggregate(mcag with countdistinct as total)`)
				.pipe(map(x => x.value[0].total));
		} else {
			throw new Error(`No logic defined for dataset ${dataset}`);
		}
	}

	// Funds
	/**
	 * Return all fund categories available
	 */
	@Share(ShareMode.Replay)
	getFundCategories(): Observable<Array<FundCategory>> {
		return this.http.get<ODataCollectionResult<FundCategory>>(`FundCategories`)
			.pipe(map(x => x.value));
	}
	/**
	 * Return all fund types available
	 */
	@Share(ShareMode.Replay)
	getFundTypes(): Observable<Array<FundType>> {
		return this.http.get<ODataCollectionResult<FundType>>(`FundTypes`)
			.pipe(map(x => x.value));
	}


	// endregion

	// region Admin
	getTime(): Observable<string> {
		return this.http.get<string>(`/service/getTime`);
	}

	publishNewSnapshot = (data: PublishSnapshotData) => {
		const url = `/admin/PublishNewSnapshot`;
		return this.http.post<PublishSnapshotData>(url, {
			'PublishBARSYear': data.publishBARSYear,
			'MilestoneCode':   data.milestoneCode,
			'MilestoneName':   data.milestoneName,
			'Description':     data.description,
			'Comment':         data.comment,
			'Type':            data.type,
			'Baseline':        data.baseline,
			'TotalRevenues':   data.totalRevenues,
			'FilersWithData':  data.filersWithData,
			'DryRun':		   data.dryRun
		});
	};
	deleteSnapshots = (data: DeleteSnapshotsData) => {
		const url = `/admin/DeleteSnapshots`;
		return this.http.post<DeleteSnapshotsData>(url, {
			'SelectedIdsForDeletion': data.selectedIdsForDeletion,
		});
	};

	/**
	 * Get the latest job instance/status for each job type
	 */
	@Share()
	jobInstanceStatusesLatestByType():Observable<Array<JobInstanceStatus>> {
		const url = `/api/JobInstanceStatuses/LatestByType`;
		return this.http.get<ODataCollectionResult<JobInstanceStatus>>(url)
			.pipe(map(x => x.value));
	}

	// endregion
}
