// todo - maybe move this model to data-cube and rename it
export enum PivotAnnotationType {
  normalization = 'normalization',  // Value used for normalization (e.g., population for '$ per capita', enrollment for '$ per student'
  displayUnit = 'display-unit',  // Display units clarification (e.g., '% of Change', '% of Total', etc.)
  missingFilingReason = 'missing-filing-reason',  // When a government is missing data for a year (e.g., 'Did Not File', 'Pending Edits', etc.)
  accountCode = 'account-code',  // Display of account codes (e.g., 310 for Taxes)
  ranking = 'ranking', // Display of a government's ranking in a sorted data grid
  noData = 'no-data', // Display optional, alternative text in a data cell when there is no detail data records for that cell
}

/**
 * Annotations to use/add on pivot-header-cell objects
 */
export class PivotAnnotation {
  constructor(
    readonly type: PivotAnnotationType,
    public text: string
  ) {  }

}
