import {FundField, SelectedFundNode} from '../../components/funds-selector/fund-node-model';

/**
 * Parse selected fund node route parameter from a string to a selectedFundNode
 * The selected nodes id is represented in the route parameter as 'field|fund'
 * e.g. fundCategoryId|1 is fundCategoryId = 1, Governmental Fund Category
 * @param fundIdRouteParam
 */
export const selectedFundNodeStringParser = <T extends string>(fundIdRouteParam: T): SelectedFundNode | undefined => {
	if (fundIdRouteParam) {
		const [field, value] = fundIdRouteParam.split('|');
		if (Object.values(FundField).includes(field as FundField)) { // field needs to be sanitized since it is an uncontrolled variable
			return new SelectedFundNode((field !== FundField.allFunds) && (field !== FundField.fund) ? parseInt(value, 10) : value, field as FundField);
		} else {
			return undefined
		}
	} else {
		return undefined;
	}
}
