import {Injectable} from '@angular/core';
import {LoggerService} from '../../services/logger.service';
import {FitApiService} from '../../services/api/fit-api/fit-api.service';
import {AnnotatedValue, HierarchyFieldConfig} from '../../models/data-cube/hierarchy-field-config';
import {map} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {HierarchyConfig} from '../../models/data-cube/hierarchy-config';
import {AllFundsDataRow} from '../../services/api/fit-api/models/all-funds';
import {SchoolsWithDetail} from '../../services/api/fit-api/models/datasets/schools';
import {SchoolFund} from '../../services/api/fit-api/models/funds/school-fund';
import {AccountDescriptor} from '../../services/api/fit-api/models/snapshots/account-descriptor';
import {ReferenceLookup} from '../../services/api/fit-api/models/snapshots/reference-lookup';
import {FundField, SelectedFundNode} from '../../components/funds-selector/fund-node-model';
import {DatasetDetail} from '../../services/api/fit-api/models/datasets/dataset-type';
import {Report, ReportId} from '../../models/report';
import {SnapshotWithDetail} from '../../services/api/fit-api/models/datasets/snapshot';
import {Schedule1AggregationByGovType} from '../../services/api/fit-api/models/schedule1-aggregation-by-gov-type';
import {Schedule1AggregationByGovt} from '../../services/api/fit-api/models/schedule1-aggregation-by-govt';
import {GovernmentSpecificity} from '../../services/api/fit-api/models/government-specificity';
import {Fund} from '../../services/api/fit-api/models/funds/fund';
import {Schedule9} from '../../services/api/fit-api/models/schedule9';
import {LiveWithDetail} from '../../services/api/fit-api/models/datasets/live';
import {OSPILongTermLiabilityRow} from '../../services/api/fit-api/models/schools/long-term-liability-row';
import {ReportItem} from '../../services/api/fit-api/models/schools/report-item';
import {AnnualFilingDataset} from '../../services/api/fit-api/models/datasets/annual-filing-dataset-detail';
import {BarsAggregation} from '../../services/api/fit-api/models/bars-aggregation';
import {
	FinancialReportAggregationByGovt
} from '../../services/api/fit-api/models/schools/financial-report-aggregation-by-govt';
import {
	FinancialReportAggregationByGovType
} from '../../services/api/fit-api/models/schools/financial-report-aggregation-by-gov-type';

@Injectable({
	providedIn: 'root'
})
export class FinancialReportService {

	constructor(
		private fitApiService: FitApiService,
		private logger: LoggerService,
	) {
	}

	//region Data Cube
	fsSectionBarsHierarchy<T extends AllFundsDataRow<BarsAggregation>>
	(
		datasetDetail: DatasetDetail,
		report: Report<T>,
		expenditureObject?: boolean)
	{
		const fields = [];

		// if summary report add 'fs section' report row
		if (report.id === ReportId.summary || report.id === ReportId.schoolsSummary) {
			fields.push(
				new HierarchyFieldConfig<T>(
					'fsSectionId', true, false, false,
					this.referenceSortOrder(datasetDetail?.detail?.financialSummarySections),
					this.referenceDisplayValue(datasetDetail?.detail?.financialSummarySections),
				),
			)
		} else if (expenditureObject && datasetDetail instanceof AnnualFilingDataset &&
			(report.id === ReportId.expenditures || report.id === ReportId.expendituresAndOtherDecreases)) {
			fields.push(
				new HierarchyFieldConfig<AllFundsDataRow<Schedule1AggregationByGovt> | AllFundsDataRow<Schedule1AggregationByGovType>>(
					'expenditureObjectId', true, false, false,
					this.referenceSortOrder(datasetDetail?.detail?.expenditureObjects),
					this.referenceDisplayValue(datasetDetail?.detail?.expenditureObjects),
					this.referenceExpenditureObject()
				),
			)
		}

		return of({
			fields: [
				...fields,
				new HierarchyFieldConfig<T>(
					'basicAccountId', true, false, false,
					this.accountSortOrder(datasetDetail?.detail?.accountDescriptors),
					this.accountDisplayValue(datasetDetail?.detail?.accountDescriptors),
					this.accountCode(datasetDetail?.detail?.accountDescriptors)
				),
				new HierarchyFieldConfig<T>(
					'subAccountId', true, false, false,
					this.accountSortOrder(datasetDetail?.detail?.accountDescriptors),
					this.accountDisplayValue(datasetDetail?.detail?.accountDescriptors),
					this.accountCode(datasetDetail?.detail?.accountDescriptors)
				),
				new HierarchyFieldConfig<T>(
					'elementId', true, false, false,
					this.accountSortOrder(datasetDetail?.detail?.accountDescriptors),
					this.accountDisplayValue(datasetDetail?.detail?.accountDescriptors),
					this.accountCode(datasetDetail?.detail?.accountDescriptors)
				),
				new HierarchyFieldConfig<T>(
					'subElementId', false, false, false,
					this.accountSortOrder(datasetDetail?.detail?.accountDescriptors),
					this.accountDisplayValue(datasetDetail?.detail?.accountDescriptors),
					this.accountCode(datasetDetail?.detail?.accountDescriptors)
				),
			]
		} as HierarchyConfig<T>);

	}

	annualFilingDebtCategoriesHierarchy<T extends AllFundsDataRow<Schedule9>>
	(datasetDetail: DatasetDetail)
	{
		if ((datasetDetail instanceof LiveWithDetail || datasetDetail instanceof SnapshotWithDetail)) {
			return of({
				fields: [
					new HierarchyFieldConfig<T>(
						'bonanzaCategoryId', true, true, false,
						this.referenceSortOrder(datasetDetail?.detail.bonanzaSchedule9Categories),
						this.referenceDisplayValue(datasetDetail?.detail.bonanzaSchedule9Categories)
					),
					new HierarchyFieldConfig<T>(
						'bonanzaTypeId', true, false, false,
						this.referenceSortOrder(datasetDetail?.detail.bonanzaSchedule9Types),
						this.referenceDisplayValue(datasetDetail?.detail.bonanzaSchedule9Types)
					),
					new HierarchyFieldConfig<T>(
						'debtCategoryItemId', false, false, false,
						this.referenceSortOrder(datasetDetail?.detail.debtCategoryItems),
						this.referenceDisplayValue(datasetDetail?.detail.debtCategoryItems)
					)
				]
			} as HierarchyConfig<T>);
		} else {
			throw new Error(`No logic defined for this dataset ${datasetDetail} or report not found`);
		}
	}

	schoolsDebtCategoriesHierarchy<T extends AllFundsDataRow<OSPILongTermLiabilityRow>>
	(datasetDetail: DatasetDetail)
	{
		if (datasetDetail instanceof SchoolsWithDetail) {
			return of({
				fields: [
					new HierarchyFieldConfig<T>(
						'multiYearSortOrder', false, false, false,
						HierarchyFieldConfig.defaultSortOrder,  // multi-year sort order is the sort order :-)
						this.schoolDebtDisplayValue(datasetDetail?.detail.reportItems)
					),
				]
			} as HierarchyConfig<T>);
		} else {
			throw new Error(`No logic defined for this dataset ${datasetDetail} or report not found`);
		}
	}

	schoolsFundsHierarchy<T extends
		AllFundsDataRow<FinancialReportAggregationByGovt> |
		AllFundsDataRow<FinancialReportAggregationByGovType>>
	(
		specificity: GovernmentSpecificity,
		dataset: SchoolsWithDetail,
		fundNode?: SelectedFundNode,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fundsLookup: Array<Fund> = [], // n/a for schools
		mustIncludeYears: Array<AnnotatedValue> = []
	)
	{
		return forkJoin([this.fitApiService.getFundCategories(), this.fitApiService.getFundTypes()])
			.pipe(map(([fundCategories, fundTypes]) => {
				// Prepare years array to force display of years w/enrollment annotations
				const yearField = new HierarchyFieldConfig<T>('year', false);
				yearField.mustIncludeValues = mustIncludeYears;

				const fieldConfigs = [
					yearField,
					new HierarchyFieldConfig<T>('allFunds'),
					new HierarchyFieldConfig<T>(
						'fundCategoryId', true, false, false,
						this.referenceSortOrder(fundCategories),
						this.referenceDisplayValue(fundCategories)
					),
					new HierarchyFieldConfig<T>(
						'fundTypeId', specificity === GovernmentSpecificity.Government, false, false,
						this.referenceSortOrder(fundTypes),
						this.referenceDisplayValue(fundTypes)
					)
				] as HierarchyFieldConfig<T>[];

				// Add fund level field to the hierarchy if we are viewing an individual government
				if (specificity === GovernmentSpecificity.Government) {
					fieldConfigs.push(
						new HierarchyFieldConfig<T>(
							'fundCode' as keyof T, false, false, false,
							this.schoolFundCodeSortOrder(dataset?.detail?.funds),
							this.schoolFundCodeDisplayValue(dataset?.detail?.funds)
					));
				}

				// Convert to correct fund field name since this is different between SAO and OSPI
				const fundFieldName = fundNode?.field === FundField.fund ? 'fundCode' : fundNode?.field.toString();
				this.consolidateFundLevels<T>(fieldConfigs, fundFieldName);

				return { fields: fieldConfigs } as HierarchyConfig<T>;
			}));
	}

	// todo FIT-1736 (https://youtrack.thinkfellow.com/youtrack/issue/FIT-1736/Schools-GetLongTermLiabilities-Endpoint-and-schoolsDebtAndLiabilitiesFundsHierarchy-refactor)
	// fix endpoint to include fundCategoryId and fundTypeId so they can be added to this columnHierarchy config function
	schoolsDebtAndLiabilitiesFundsHierarchy<T extends AllFundsDataRow<OSPILongTermLiabilityRow>>
	(
		specificity: GovernmentSpecificity,
		dataset: SnapshotWithDetail,
		fundNode?: SelectedFundNode,
		fundsLookup?: Array<Fund>,
		mustIncludeYears: Array<AnnotatedValue> = [])
	{
		const yearField = new HierarchyFieldConfig<T>('fy', false);
		yearField.mustIncludeValues = mustIncludeYears;

		const fieldConfigs = [
			yearField,
			new HierarchyFieldConfig<T>('allFunds', false),
		] as HierarchyFieldConfig<T>[];

		return of({ fields: fieldConfigs } as HierarchyConfig<T>);
	}

	annualFilingSch1ColumnHierarchy<T extends
		AllFundsDataRow<Schedule1AggregationByGovt> |
		AllFundsDataRow<Schedule1AggregationByGovType>>
	(
		specificity: GovernmentSpecificity,
		dataset: SnapshotWithDetail,
		fundNode?: SelectedFundNode,
		fundsLookup: Array<Fund> = [],
		mustIncludeYears: Array<AnnotatedValue> = [])
	{
		return forkJoin([this.fitApiService.getFundCategories(), this.fitApiService.getFundTypes()])
			.pipe(map(([fundCategories, fundTypes]) => {
				// Prepare years array to force display of years even if missing a filing
				const yearField = new HierarchyFieldConfig<T>('year', false);
				yearField.mustIncludeValues = mustIncludeYears;

				const fieldConfigs = [
					yearField,
					new HierarchyFieldConfig<T>('allFunds'),
					new HierarchyFieldConfig<T>(
						'fundCategoryId', true, false, false,
						this.referenceSortOrder(fundCategories),
						this.referenceDisplayValue(fundCategories)
					),
					new HierarchyFieldConfig<T>(
						'fundTypeId', specificity === GovernmentSpecificity.Government, false, false,
						this.referenceSortOrder(fundTypes),
						this.referenceDisplayValue(fundTypes)
					)
				] as HierarchyFieldConfig<T>[];

				// Add fund level field to the hierarchy if we are viewing an individual government
				if (specificity === GovernmentSpecificity.Government) {
					fieldConfigs.push(new HierarchyFieldConfig<T>(
						'fund' as keyof T, false, false, false,
						HierarchyFieldConfig.defaultSortOrder,
						this.annualFilingFundDisplayValue(fundsLookup)
					));
				}

				// Remove fund levels based on selected fund node; start after first element which is FY
				this.consolidateFundLevels<T>(fieldConfigs, fundNode?.field);

				return { fields: fieldConfigs } as HierarchyConfig<T>;
			}));
	}

	annualFilingSch9ColumnHierarchy<T extends AllFundsDataRow<Schedule9>>
	(
		specificity: GovernmentSpecificity,
		dataset: SnapshotWithDetail,
		fundNode?: SelectedFundNode,
		fundsLookup?: Array<Fund>,
		mustIncludeYears: Array<AnnotatedValue> = [])
	{
		const yearField = new HierarchyFieldConfig<T>('year', false);
		yearField.mustIncludeValues = mustIncludeYears;

		const fieldConfigs = [
			yearField,
			new HierarchyFieldConfig<T>('allFunds', false),
		] as HierarchyFieldConfig<T>[];

		return of({ fields: fieldConfigs } as HierarchyConfig<T>);
	}

	/**
	 * Remove fund levels based on selected fund node; start after first element which is FY or year
	 *
	 * @param fieldConfigs - full column hierarchy with fund levels
	 * @param fundFieldName - filtered fund level (no need to keep parent fund levels above this one for display)
	 * @private
	 */
	private consolidateFundLevels<T extends object>(fieldConfigs: HierarchyFieldConfig<T>[], fundFieldName: string | undefined) {
		for (let i = 1; i < fieldConfigs.length; i++) {
			const config = fieldConfigs[i];
			if (config.name === fundFieldName) {
				break;
			}
			// If we have not encountered the selected fund node level (working top-down), then remove this level from the configs
			fieldConfigs.splice(i, 1);
			i--;
		}
	}

	private schoolFundCodeDisplayValue(fundCodes: SchoolFund[]) {
		return function (code: number | string | null): string {
			if (code === null) {
				return '';
			}
			const fundCode = fundCodes.find(fc => fc.code === code);
			return fundCode?.description ?? code.toString();
		}
	}

	private schoolDebtDisplayValue(reportItems: ReportItem[]) {
		return function (code: number | string | null): string {
			if (code === null) {
				return '';
			}
			const item = reportItems.find(i => i.multiYearSortOrder === code && i.reportNo === '013');
			return item?.title ?? code.toString();
		}
	}

	private annualFilingFundDisplayValue(funds: Fund[]) {
		return function (code: number | string | null): string {
			if (code === null) {
				return '';
			}
			const fund = funds.find(f => f.fundNumber === code);
			return fund?.fundFullName ?? code.toString();
		}
	}

	private schoolFundCodeSortOrder(fundCodes: SchoolFund[]) {
		return function (code: number | string | null): number | string {
			if (code === null) {
				return -1;
			}
			const fundCode = fundCodes.find(fc => fc.code === code);
			return fundCode?.sortOrder ?? code;
		}
	}

	private accountDisplayValue(accounts: AccountDescriptor[]) {
		return function (id: number | string | null): string {
			if (id === null) {
				return '';
			}
			const account = accounts.find(cat => cat.id === id);
			return account?.name ?? id.toString();
		}
	}

	private accountSortOrder(accounts: AccountDescriptor[]) {
		return function (id: number | string | null): number | string {
			if (id === null) {
				return '';
			}
			const account = accounts.find(acc => acc.id === id);
			if (account?.categoryDisplay === '') {
				return 'XXX' + id.toString(); // If no category display (BARS account number), then sort at the bottom (schools)
			}
			return account?.categoryDisplay ?? id.toString();
		}
	}

	private accountCode(accounts: AccountDescriptor[]) {
		return function (id: number | string | null): string | null {
			if (id === null) {
				return '';
			}
			const account = accounts.find(acc => acc.id === id);
			if (account?.categoryDisplay === '') {
				return null;
			}
			return account?.categoryDisplay ?? id.toString();
		}
	}

	private referenceDisplayValue(refData: ReferenceLookup<number | string>[]) {
		return function (id: number | string | null): string {
			if (id === null) {
				return '';
			}
			const row = refData.find(ref => ref.id === id);
			return row?.name ?? id.toString();
		}
	}

	private referenceSortOrder(refData: ReferenceLookup<number | string>[]) {
		return function (id: number | string | null): number | string {
			if (id === null) {
				return '';
			}
			const row = refData.find(ref => ref.id === id);
			return row?.sortOrder ?? id.toString();
		}
	}

	private referenceExpenditureObject() {
		return function (id: number | string | null): string | null {
			return id ? id.toString() : null;
		}
	}
	//endregion

}
