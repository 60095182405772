import {Palette} from './palette';
import {Ranking} from './ranking';
import {FinancialSummarySectionId} from '../services/api/fit-api/models/financial-summary';

export class DollarsCategory {
	constructor(
		public name: string,
		public id: FinancialSummarySectionId,
		public sortOrder: number,
		public logicalAccount: string,
		public color: Palette,
		public basicAccounts: Array<BasicAccount> = [],
		public hint?: string,
		public amount?: number,
		public filersForFsSection?: number,
		public expectedFilersStatewide?: number
	) {
	}
}

export class BasicAccount implements Ranking{
	constructor(
		public name: string,
		public id: number,
		public categoryDisplay: string,
		public logicalAccount: string,
		public amount?: number,

		// percentage fields
		public percentageOfTotal?: number,
		public ranking?: number
	) {
	}
}
