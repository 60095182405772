export enum MissingFilingReason {
	NotActive = 'NotActive',
	NoFiling = 'NoFiling',
	PendingEdits = 'PendingEdits',
	NoActivity = 'NoActivity'
}

export const missingFilingReasonLookup: Array<{text: string, textSentenceCase: string, value: keyof typeof MissingFilingReason}> = [
	{text: 'Not Active', textSentenceCase: 'Not active', value: MissingFilingReason.NotActive},
	{text: 'No Filing', textSentenceCase: 'No filing', value: MissingFilingReason.NoFiling},
	{text: 'Pending Revisions',textSentenceCase: 'Pending revisions', value: MissingFilingReason.PendingEdits},
	{text: 'No Activity', textSentenceCase: 'No activity', value: MissingFilingReason.NoActivity},
];
