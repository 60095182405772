import {DatasetSource} from '../services/api/fit-api/models/datasets/dataset-source';
import {DatasetDetail} from '../services/api/fit-api/models/datasets/dataset-type';
import {Observable} from 'rxjs';
import {SelectedFundNode} from '../components/funds-selector/fund-node-model';
import {HierarchyConfig} from './data-cube/hierarchy-config';
import {Palette} from './palette';
import {GovernmentId} from '../services/api/fit-api/models/government-id';
import {GovernmentSpecificity} from '../services/api/fit-api/models/government-specificity';
import {Fund} from '../services/api/fit-api/models/funds/fund';
import {AnnotatedValue} from './data-cube/hierarchy-field-config';
import {SelectedCounties} from '../components/counties-selector/counties-model';

export interface Report<T extends object> {
	id: ReportId;
	name: string;
	// datasetSource report is associated with
	financialDatasetSource: DatasetSource;
	// route param (used for financial report page)
	routeParam: RouteParam;
	// property to pull amounts from
	amountField: keyof T;
	// color for card styling
	amountColor?: Palette;
	// filterLevel eg fsSectionId
	filterLevel?: keyof T;
	// filterValues eg [20, 25]
	filterValues?: Array<number>;
	// requires certain level of user access (or undefined)
	restrictedTo?: UserAccessLevel;
	// sort order of reports (e.g. order shown in title dropdown in financial report)
	sortOrder: number;
	// hint language for report
	hint?: string;
	// can have grand totals
	hasGrandTotals: boolean;
	// this is the function to call to build the correct column hierarchy for this report
	columnHierarchyFunction: (
		specificity: GovernmentSpecificity,
		datasetDetail: DatasetDetail | null,
		selectedFundNode?: SelectedFundNode,
		fundsLookup?: Array<Fund>,
		mustIncludeYears?: Array<AnnotatedValue>) => Observable<HierarchyConfig<T>>;
	// this is the function to call to build the correct row hierarchy for this report
	rowHierarchyFunction: <RHF extends T> (
		datasetDetail: DatasetDetail | null,
		report?: Report<RHF>, expenditureObject?: boolean) => Observable<HierarchyConfig<RHF>>;
	// Function to fetch data for this report
	dataSource: <DS extends T>(
		datasetDetail: DatasetDetail | null,
		report: Report<DS>,
		governmentId: GovernmentId,
		startYear: number,
		endYear: number,
		selectedFundNode?: SelectedFundNode,
		excludeInternalService?: boolean,
		selectedCounties?: SelectedCounties) => Observable<Array<DS>>;
}

// a report's required user access
export enum UserAccessLevel {
	global = 'global', // User.hasGlobalAccess
	mcag = 'mcag' // User.hasAccessToMcag
}

// unique identifier for each report
export enum ReportId {
	summary = 'summary',
	revenues = 'revenues',
	expenditures = 'expenditures',
	debtAndLiabilities = 'debtAndLiabilities',
	revenuesAndOtherIncreases = 'revenuesAndOtherIncreases',
	expendituresAndOtherDecreases = 'expendituresAndOtherDecreases',
	schoolsSummary = 'schoolsSummary',
	schoolsRevenues = 'schoolsRevenues',
	schoolsExpenditures = 'schoolsExpenditures',
	schoolsDebtAndLiabilities = 'schoolsDebtAndLiabilities',
	schoolsRevenuesAndOtherIncreases = 'schoolRevenuesAndOtherIncreases',
	schoolsExpendituresAndOtherDecreases = 'schoolExpendituresAndOtherDecreases',
}

// parameter from route that represents which report should be shown
export enum RouteParam {
	summary = 'summary',
	revenues = 'revenues',
	expenditures = 'expenditures',
	debtLiabilities = 'debt-liabilities',
	revenuesOtherIncreases = 'revenues-other-increases',
	expendituresOtherDecreases = 'expenditures-other-decreases'
}

